import styled from "styled-components";

export const BookingWrapper = styled.div`
    padding: 12% 5%;

    .payment {
        border-radius: 5px;
        overflow: clip;
    }

    .total_amount {
        font-weight: bold;
    }

    .pricing {
        font-weight: 600;
        color: var(--bs-gray-700);
    }

    .price {
        font-weight: 600;
        display: flex;
        flex-direction: column;
        gap: 6px;
        
        .value {
            font-size: 21px;
            color: var(--primary-color);
        }
    }

    .total {
        font-size: 21px;
        color: var(--primary-color);
    }
`;

export const LoadingState = styled.div`
    height: 100vh;
    width: 100vw;
    background: white;

    .preloader {
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: center;
        font-size: 21px;
        font-weight: 600;
        color: var(--primary-color);
    }
`;