import { memo, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { login } from "../../../store/actions/thunks/users";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { usersState } from "../../../store/selectors";

const Login = ({show, toggleLogin = () => {}}) => {
    
    const dispatch = useDispatch();
    const { register, handleSubmit } = useForm();
    const users = useSelector(usersState);
    // const [data, setData] = useState("");

    const postLogin = useCallback((data) => {
        // console.log(data);
        dispatch(login(data));
    }, []);

    useEffect(() => {
        if(users.data && show) {
            toggleLogin()
        }
    }, [users, show])

    // console.log(data);


    return (
        <div className={`modal fade${show ? ' show' : ''}`} id="loginModal" tabIndex="-1" role="dialog" aria-labelledby="loginModalLabel" aria-hidden="true">
            <div className="modal-dialog mt-5" role="document">
                <div className="modal-content">
                <div className="modal-header">
                    <button onClick={() => toggleLogin()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true"><i className="fa fa-times"></i></span>
                    </button>
                    </div>
                <div className="modal-body ">
                    <h5 className="text-center">Welcome to LokaLocal</h5>
                    <form onSubmit={handleSubmit((data) => postLogin(data))}>
                        <div className="form-group mb-3">
                        <label htmlFor="email">Email address</label>
                        <input {...register("email")} type="email" className="form-control" id="email" placeholder="johndoe@gmail.com"/>
                        </div>
                        <div className="form-group mb-3">
                        <label htmlFor="password">Password</label>
                        <input {...register("password")} type="password" className="form-control" id="password" placeholder="Your Password"/>
                        </div>
                        <button type="submit" className="d-block pink-button text-center mb-3">Log in</button>
                    </form>
                    <p className="font-weight-normal text-center">Don't have an account?  <a href="" className="sign-up " data-toggle="modal" data-target="#loginModal">Sign up</a></p>
                </div>
                </div>
            </div>
        </div>
    );
}

export default memo(Login)