import styled from 'styled-components';

export const StyledButton = styled.button`
    border: none;
`;

export const LogoButton = styled.button`
    width: 160px;
    height: auto;
    outline: none;
    border: none;
    background: transparent;

    img {
        width: 100%;
    }
`;

export const SelfGuideWrapper = styled.div`
    .list-wrapper {
        // max-height: 87vh;
    }
`;