import {memo, useState, useEffect} from 'react';
import Navigation from '../../components/navigation';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import * as selectors from '../../store/selectors';
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { BookingWrapper, LoadingState } from './Styles';

const stripePromise = loadStripe("pk_test_p6h5QMRAki9MI8pjn0Y2FGfL00S7UVXP0f");

const Booking = () => {

    const navigate = useNavigate();
    const [clientSecret, setClientSecret] = useState("");
    const tourDetailsState = useSelector(selectors.tourDetailsState);
    const tourDetails = tourDetailsState.data;

    useEffect(() => {
        // Create PaymentIntent as soon as the page loads
        fetch("http://localhost:8000/create_payment_intent", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ items: [{ id: "cermin-lake" }] }),
        })
        .then((res) => res.json())
        .then((data) => setClientSecret(data.clientSecret));
    }, []);

    useEffect(() => {
        console.log(tourDetails)
        if(!tourDetails) {
            navigate('/')
        }
    }, [tourDetails]);

    const appearance = {
        theme: 'stripe',
    };
    const options = {
        clientSecret,
        appearance,
    };

    if (!tourDetails) {
        return (
            <LoadingState>
                <Navigation />
                <div className="preloader">
                    <span>Loading...</span>
                </div>
            </LoadingState>
        )
    }

    return (
        <BookingWrapper>
            <Navigation />
            <div className="container-fluid m-5-hor">
        <div className="row">

            
            <div className="col-md-7">
            <h2 className=" mb-3">Review and pay</h2>

            <h5 className=" mb-2">Booking Information</h5>

            <form id="booking_form" method="post" className="form-border-bottom  pb-5 mb-5 border-bottom">
                <div className="form-group mb-4">
                <label for="first_name">First name *</label>
                <input type="text" className="border-0 form-control" name="first_name" id="first_name" value="M" placeholder="Exp:John" required=""/>
                </div>
                <div className="form-group mb-4">
                <label for="last_name">Last name *</label>
                <input type="text" className="border-0 form-control" name="last_name" id="last_name" value="Hidayat" placeholder="Exp:Doe"/>
                </div>
                <div className="form-group mb-4">
                <label for="email">Email address * </label> 
                <small className="pink-text">(your voucher will be sent here, make sure it is correct)</small>
                <input type="email" className="border-0 form-control" name="email" id="email" value="dayatsmkn26@gmail.com" placeholder="Exp:johndoe@gmail.com" required=""/>
                </div>
                <div className="form-group mb-4">
                <label for="phone">Mobile Phone *</label>
                <input type="tel" className="border-0 form-control" name="phone" id="phone" value="" placeholder="Exp:+6012345678" required=""/>
                </div>
            </form>
            </div>
            {clientSecret && (
                <Elements options={options} stripe={stripePromise}>
                    <CheckoutForm />
                </Elements>
            )}
            <div className="col-md-5 payment border p-0 h-100">

            <div className="image-container mb-3">
                <img className="w-100 h-100" src={tourDetails.cover} alt=""/>
            </div>
                        
            <div className="text-container">
                <div className="container border-bottom py-4">
                    <h4 className=" mb-3">{tourDetails.title}</h4>
                </div>
                <div className="container border-bottom py-4 price">
                    <span>Standard Price</span>
                    <span className='value'>RM {tourDetails.price.toFixed(2)}</span>
                </div>

                <div className="container border-bottom py-4">
                    <div className="row pricing">
                        <div className="col-9">
                        Self Guide Tour x 1
                        </div>
                        <div className="col-1 text-right">
                        RM
                        </div>
                        <div className="col-2 text-right">
                            {tourDetails.price.toFixed(2)}
                        </div>
                    </div>
                </div>
                
                <div className="container py-4">
                    <div className="row">
                        <div className="col-9">
                        <b className='total'>Total</b>
                        </div>
                        <div className="col-1 text-right">
                        <b>RM</b>
                        </div>
                        <div className="col-2 text-right">
                        <b>
                            <span className="total_amount">
                                {tourDetails.price.toFixed(2)}
                            </span>
                        </b>
                        </div>
                    </div>
                </div>
                
                {/* <div className="container py-4">
                <a href="https://lokalocal.com/info#policy" className="pink-text" target="_blank">Cancellation &amp; Refund Policy</a>
                <p className="mt-2">In case of unsuccessful dates, a 100% refund is guaranteed.</p>
                </div> */}
            </div>

            </div>

        </div>
            </div>
        </BookingWrapper>
    )
}

export default memo(Booking);