import { memo, useEffect } from "react";
import Slider from "react-slick";
import { SliderArrow } from "../homepage/Styles";
import Navigation from "../../components/navigation";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import * as actions from '../../store/actions/thunks/selfguide';
import * as selectors from '../../store/selectors';
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { DetailWrapper, LoadingState } from "./Styles";
import { fetchTopExperience } from '../../store/actions/thunks/selfguide';
import Cards from "../../components/cards";

const Detail = ({
    tourId
}) => {
    const { slug } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const tourDetailsState = useSelector(selectors.tourDetailsState);
    const topExperienceState = useSelector(selectors.topExperienceState);
    const tourDetails = tourDetailsState.data;
    const loadFailed = tourDetailsState.loadFailed;
    const isLoading = tourDetailsState.loading;
    const topExperience = topExperienceState.data;
    const placeholderArr = [1,2,3,4];

    useEffect(() => {
        if(loadFailed) navigate('/')
    }, [loadFailed]);

    useEffect(() => {
        dispatch(actions.fetchTourDetails(slug))
        dispatch(fetchTopExperience())
    }, [slug]);

    const PrevButton = (props) => {
		const { style, onClick } = props;

		return (
			<SliderArrow 
				className='slick-arrow prev'
				style={{ ...style, display: "block"}}
				onClick={onClick}
			>
				<i className="fa fa-chevron-left" aria-hidden="true"></i>
			</SliderArrow>
		);
	}
	
	const NextButton = (props) => {
		const { style, onClick } = props;
		return (
			<SliderArrow 
				className='slick-arrow next'
				style={{ ...style, display: "block"}}
				onClick={onClick}
			>
				<i className="fa fa-chevron-right" aria-hidden="true"/>
			</SliderArrow>
		);
	}

    const freeExperience = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		initialSlide: 0,
		adaptiveHeight: true,
		nextArrow: <NextButton />,
      	prevArrow: <PrevButton />,
		responsive: [
		  {
			breakpoint: 1024,
			settings: {
			  slidesToShow: 3,
			  slidesToScroll: 3,
			  infinite: true,
			  dots: false
			}
		  },
		  {
			breakpoint: 600,
			settings: {
			  slidesToShow: 2,
			  slidesToScroll: 2,
			  initialSlide: 2
			}
		  },
		  {
			breakpoint: 480,
			settings: {
			  slidesToShow: 1,
			  slidesToScroll: 1
			}
		  }
		]
	};

    const addToCart = () => {
        navigate('/booking');
    }

    if (isLoading) return (
        <LoadingState>
            <Navigation />
            <div className="preloader">
                <span>Loading...</span>
            </div>
        </LoadingState>
    );

    return (
        <>
            <Navigation />
            <DetailWrapper>
                <section className="jumbotron detail" style={{backgroundImage: `url(${tourDetails.panocover})`}}>
                    <div className="container">
                        <div className="row">
                        <div className="col-md-8 m-auto">
                            <div className="jumbomain">
                            <h2>{tourDetails.title}</h2>
                            </div>
                        </div>
                        </div>  
                    </div>
                    <div className="relative">
                        <div className="detailbtn">
                            <button className="btn" onClick={() => navigate('/')}>
                                <svg className="mdi-icon " width="20" height="20" fill="currentColor" viewBox="0 0 24 24">
                                    <path d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"></path>
                                </svg>
                                Explore All Tours
                            </button>
                        </div>
                    </div>
                    </section>

                    <section className="py-5">
                    <div className="container">
                        <div className="row">
                        <div className="col-md-9">
                            <h2>{tourDetails.title}</h2>
                            <p className="half-color">
                                {tourDetails.desc}
                            </p>
                            {tourDetails.media.map((media, key) => (
                                <div key={key}>
                                    <div className="video">
                                        <video controls>
                                            <source src={media.url} type="video/mp4"/>
                                        Your browser does not support the video tag.
                                        </video> 
                                    </div>
                                    <h4>{media.title}</h4>
                                    <p className="half-color mb-4">
                                        {media.desc}
                                    </p>
                                </div>
                            ))}

                            <h4>lots to do</h4>
                            <ul className="half-color">
                            <li><i className="fa fa-connectdevelop"></i> {tourDetails.tags.place_count} digital reconstructions</li>
                            {/* <li><i className="fa fa-user"></i> 3000m</li>
                            <li><i className="fa fa-hourglass-o"></i> 500 BC to 300 AD</li> */}
                            <li><i className="fa fa-headphones"></i> {tourDetails.tags.audio_length} minutes</li>
                            <li><i className="fa fa-file-image-o"></i> {tourDetails.tags.image_count} images</li>
                            {tourDetails.tags && tourDetails.tags.streetview &&
                                <li><i className="fa fa-street-view"></i> Street view</li>
                            }
                            </ul>

                        </div>

                        <div className="col-md-3 relative">
                            <div className="flybox sticky-top">
                            <p className="half-color">Share Us</p>
                            <div className="mb-3">
                                <span><i className="fa fa-facebook-f"></i></span>
                                <span><i className="fa fa-twitter"></i></span>
                                <span><i className="fa fa-instagram"></i></span>
                            </div>
                            <hr/>
                            <button className="btn pink-button" onClick={() => addToCart()}>Add to Cart</button>
                            </div>
                        </div>

                        </div>
                    </div>
                    </section>

                    <section className="gray">
                    <div className="container">
                        <div className="row">
                        <div className="col-md-3 m-left m-md-0 mb-5">
                            <p className="half-color mb-1">BEST SELLING TRIPS</p>
                            <h3 className="mb-3">Top Rated Our Experiences Vr</h3>
                            <p className="half-color">lokalocal enriches Cultural Heritage Sites and City Tours with
                Augmented Reality Expeditions. </p>
                            <a className="btn pink-button" href="#">View All</a>
                        </div>
                        <div className="col-md-8 m-auto">

                            <div className="vr-catgor p-0">
                            <Slider {...freeExperience} id="best" className="row mx-0 owl-carousel owl-theme">
                                {topExperience ? topExperience.map((item, index) => (
                                    <Cards
                                        key={index}
                                        {...item}
                                    />
                                )) : (
                                    placeholderArr.map((i) => (
                                        <Cards key={i} loading topExperience/>
                                    ))
                                )}
                            </Slider>
                            </div>
                        </div>
                        </div>
                    </div>
                    </section>
            </DetailWrapper>
        </>
    );
}

export default memo(Detail);