import React, { memo, useState } from 'react';
import { LogoButton } from './Styles'
import { useNavigate } from 'react-router-dom';

const TopNavigation = () => {

    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div id="topnav" class="relative">
            <header>
                <div class="topmenu">
                    <div class="brand">
                        <LogoButton onClick={() => navigate('/')}><img src="/img/logo.png" alt="logo"/></LogoButton>
                    </div>
                    <div class="menu">
                        <ul class="mainmenunew mobilehide">
                            <li>
                                <h4 class="mb-0">My Purchases</h4>
                            </li>
                        </ul>
                        <ul class="mainmenunew right">
                            <li class="dropdown">
                                <a class="icon-login" href="#">
                                    <i class="fa fa-bars"></i>
                                    <i class="big fa fa-user-circle"></i>
                                    <span class="loginicon"></span>
                                </a>
                                {/* <ul class="maindropdown">
                                    <li><a href="#" data-toggle="modal" data-target="#logout">Log out</a></li>
                                </ul> */}
                            </li>
                        </ul>
                    </div>
                </div>
            </header>
        </div>
    );
}

export default memo(TopNavigation)