import { memo } from 'react';

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="row">

                    <div className="col-md-4 mb-4 col-sm-6">
                    <select name="site_locale" className="form-control">
                        <option value="https://www.lokalocal.com/online-experiences" selected="">English</option>
                        <option value="https://zh-s.lokalocal.com/online-experiences">简体中文</option>
                        <option value="https://zh-t.lokalocal.com/online-experiences">繁体中文</option>
                    </select>
                    <p className="mt-3 mb-1">Review us on:</p>
                    <div className="logofooter">
                        <a href="https://loka.fun/write-a-review" target="_blank" className="trpadvsor">
                        <img src="https://www.lokalocal.com/assets/img/tripadvsor.jpg" alt="tripadvsor"/>
                        </a>
                    </div>
                    </div>

                <div className="col-md-2 col-sm-6">
                    <h6 className="headingfooter">About us</h6>
                    <ul>
                    <li><a href="https://www.lokalocal.com/aboutus">About us</a></li>
                    <li><a href="https://www.lokalocal.com/blog" target="_blank">Blog</a></li>
                    <li><a href="https://www.lokalocal.com/press">Press</a></li>
                    <li><a href="https://www.lokalocal.com/career">Careers</a></li>
                    </ul>
                </div>

                <div className="col-md-2 mb-4 col-sm-6">
                <h6 className="headingfooter">Follow us</h6>
                <ul>
                    <li><a href="https://www.facebook.com/lokalocalmy" target="_blank" className="svg footer-social-link footer-social-link-facebook"><i className="fa fa-facebook-f"></i>Facebook</a></li>
                    <li><a href="https://www.instagram.com/lokalocal/" target="_blank" className="svg footer-social-link footer-social-link-instagram"><i className="fa fa-instagram"></i>Instagram</a></li>
                    <li><a href="https://www.youtube.com/channel/UC1yGwRizYoRCtsdgsxjn81Q" target="_blank" className="svg footer-social-link footer-social-link-youtube"><i className="fa fa-youtube"></i>Youtube</a></li>
                    <li><a href="https://twitter.com/lokalocal" target="_blank" className="svg footer-social-link footer-social-link-youtube"><i className="fa fa-twitter"></i>Twitter</a></li>
                </ul>
                </div>

                <div className="col-md-3 mb-4 col-sm-6 col-xs-12">
                <h6 className="headingfooter">Join our Newsletter</h6>
                <p>Best and latest travel inspiration, offers and awesome contests in your inbox.</p>
                <form id="subscribe" className="form-border-bottom ">
                    <div className="form-group mb-2">
                    <input type="name" className="border-0 form-control" name="subscribe_name" id="subscribe_name" value="" placeholder="Your Name"/>
                    </div>
                    <div className="form-group mb-3">
                    <input type="email" className="border-0 form-control" name="subscribe_email" id="subscribe_email" value="" placeholder="Your Email"/>
                    </div>
                </form>
                <button className="btn pink-button">Subscribe</button>
                </div>

                <div className="col-12">
                    <div className="bottom">
                    <div className="row mx-0">
                        <div className="col-md-4 px-0">
                        <span><img src="https://www.lokalocal.com/assets/img/logo-grey.svg"/> 
                        ©2022 LokaLocal.com</span>
                        </div>
                    </div>
                    </div>
                </div>
                </div>
            </div>
        </footer>
    );
}

export default memo(Footer);