import styled from 'styled-components';

export const PinkButton = styled.button`
    font-weight: 700;
    text-decoration: none;
    background: #ff5976;
    width: max-content;
    padding: 6px 30px!important;
    background: #f71d43;
    border-radius: 4px;
    color: #fff!important;
    margin-top: 5px;
`;

export const StyledSection = styled.section`
    background: black !important;
    height: 85vh;
`;