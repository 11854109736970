import { memo } from "react";
import Navigation from "../../components/navigation";
import { useNavigate } from "react-router-dom";

const NotFound = () => {
    const navigate = useNavigate();
    const backHome = () => {
        navigate('/')
    }
    return (
        <>
            <Navigation />
            <section className="jumbotron error404">
                <div className="container">
                    <div className="row">
                    <div className="col-md-8 m-auto">
                        <div className="jumbomain">
                        <img className="h-100 init" alt="404" src="img/404.gif"/>
                        <h2 className="mt-5">This page can’t be found.</h2>
                        <p className="half-color">Let’s go discover more virtual experiences in our homepage.</p>
                            <button onClick={() => backHome()} className="btn pink-button mt-3">
                                <i className="fa fa-chevron-left" aria-hidden="true"></i>
                                Back to Home
                            </button>
                        </div>
                    </div>
                    </div>  
                </div>
            </section>
        </>
    );
}

export default memo(NotFound);