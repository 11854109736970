import React, { memo } from 'react';
import { PurchaseWrapper } from './styles';
import TopNavigation from '../../virtualTour/selfGuide/topNavigation';

const Purchase = () => {
    return (
        <>
            {/* <PurchaseWrapper/> */}
            
            <TopNavigation/>
            <div className="container-fluid submenuhide" id="mapcontent">
                <div className="row">
                    <div className="col-lg-8 mx-auto">
                        <div className="contentmap purchases">
                            <div className="topmainmap">
                                <div className="heading">
                                    Click Start and Enjoy you tour on your browser
                                </div>
                                <div className="subheading mb-3">
                                    All of tours also accessible on your browser, click start and enjoy, for more tour check
                                </div>
                                <div className="subheading">
                                    <a href="#">https://www.lokalocal.com</a> 
                                </div>
                            </div>
                            <hr/>
                            <div className="botmainmap nobtn">
                                <div className="imgrounded">
                                    <img src="http://www.tinywanderer.com/wp-content/uploads/2018/05/IMG_20180306_110617.jpg"/>
                                </div>
                                <div className="detailmap">
                                    <div className="subheading">Kapas Island</div>
                                    <div className="content">
                                        <h5>What will do:</h5>
                                        <p>Kapas Island is located nearby the city of Kuala Terengganu at the eastern part of the peninsula. Kapas Island is only 2 kilometers long and 1 kilometer wide. Compared to nearby islands like Perhentian and Redang, Kapas Island is much smaller and therefore less crowded.</p>
                                        <p className="stories">44 mins - <span>4</span> stories</p>
                                        <div className="mainbutton">
                                            <a className="grey" href="#">VIEW NOW</a>
                                            <a href="#">SEE OTHER TOUR</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <div className="botmainmap nobtn">
                                <div className="imgrounded">
                                    <img src="http://www.tinywanderer.com/wp-content/uploads/2018/05/IMG_20180306_110617.jpg"/>
                                </div>
                                <div className="detailmap">
                                    <div className="subheading">Kapas Island</div>
                                    <div className="content">
                                        <h5>What will do:</h5>
                                        <p>Kapas Island is located nearby the city of Kuala Terengganu at the eastern part of the peninsula. Kapas Island is only 2 kilometers long and 1 kilometer wide. Compared to nearby islands like Perhentian and Redang, Kapas Island is much smaller and therefore less crowded.</p>
                                        <p className="stories">44 mins - <span>4</span> stories</p>
                                        <div className="mainbutton">
                                            <a className="grey" href="#">VIEW NOW</a>
                                            <a href="#">SEE OTHER TOUR</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr/>
                            <div className="botmainmap nobtn">
                                <div className="imgrounded">
                                    <img src="http://www.tinywanderer.com/wp-content/uploads/2018/05/IMG_20180306_110617.jpg"/>
                                </div>
                                <div className="detailmap">
                                    <div className="subheading">Kapas Island</div>
                                    <div className="content">
                                        <h5>What will do:</h5>
                                        <p>Kapas Island is located nearby the city of Kuala Terengganu at the eastern part of the peninsula. Kapas Island is only 2 kilometers long and 1 kilometer wide. Compared to nearby islands like Perhentian and Redang, Kapas Island is much smaller and therefore less crowded.</p>
                                        <p className="stories">44 mins - <span>4</span> stories</p>
                                        <div className="mainbutton">
                                            <a className="grey" href="#">VIEW NOW</a>
                                            <a href="#">SEE OTHER TOUR</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default memo(Purchase);