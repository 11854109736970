import axios from 'axios';
import * as actions from '../../actions';

const api = {
  baseUrl: 'http://localhost:8000/',
  login: 'auth/login',
  register: 'auth/register',
  logout: 'auth/logout',
  me: 'auth/me'
}

export const Axios = axios.create();
export const Canceler = axios.CancelToken.source();

// export const fetchUser = () => async (dispatch) => {

//   dispatch(actions.getUser.request(Canceler.cancel));

//   try {

//     const { data } = await Axios.get(`${api.baseUrl}${api.nfts}?${populate}${filter}&${music}`, {
//       cancelToken: Canceler.token,
//       params: {}
//     });
//     console.log(data)
//     dispatch(actions.getUser.success(data.data));
//   } catch (err) {
//     dispatch(actions.getUser.failure(err));
//   }
// };

export const login = (params) => async (dispatch) => {

  // dispatch(actions.getNftShowcase.request(Canceler.cancel));

  try {
    const { data } = await Axios.post(`${api.baseUrl}${api.login}`, {
      params,
      cancelToken: Canceler.token
    });

    dispatch(actions.login.success(data));
  } catch (err) {
    dispatch(actions.login.failure(err));
    console.log(err)
  }
};

export const registerUser = (params) => async (dispatch) => {

  // dispatch(actions.getNftShowcase.request(Canceler.cancel));

  try {
    const { data } = await Axios.post(`${api.baseUrl}${api.register}`, {
      params,
      cancelToken: Canceler.token
    });

    return Promise.resolve(data);
  } catch (err) {
    dispatch(actions.login.failure(err));
    return Promise.reject(err)
  }
};

export const logout = (token) => async (dispatch) => {

  // dispatch(actions.getNftShowcase.request(Canceler.cancel));

  try {
    const { data } = await Axios.post(`${api.baseUrl}${api.logout}`, {
      cancelToken: Canceler.token,
    },{ headers: {
      Authorization: 'Bearer ' + token
    }});
    return Promise.resolve(data);
  } catch (err) {
    dispatch(actions.login.failure(err));
    return Promise.reject(err)
  }
};

export const checkToken = (token) => async (dispatch) => {
  // dispatch(actions.getNftShowcase.request(Canceler.cancel));

  try {
    const { data } = await Axios.get(`${api.baseUrl}${api.me}`, {
      cancelToken: Canceler.token,
    },{ headers: {
      Authorization: 'Bearer ' + token
    }});
    return Promise.resolve(data);
  } catch (err) {
    dispatch(actions.login.failure(err));
    return Promise.reject(err)
  }
};
