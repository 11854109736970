import styled from "styled-components";

export const NavigationWrapper = styled.header`
    .modal.show {
        display: block;
        background: #00000080;
    }
`;

export const MainLogo = styled.button`
    background: transparent;
    border: none;
`;

export const LogoutButton = styled.button`
    font-family: "CircularBold";
    letter-spacing: .1px;
    font-weight: 400;
    font-size: 13px;
    line-height: 5px;
    padding: 15px 20px 15px 20px;
    border: none;

    :hover {
        background: var(--primary-color);
        color: white;
    }
`;

export const NavButton = styled.button`
    font-family: "CircularBold";
    color: rgba(255,255,255,.8);
    display: block;
    padding: 26px 15px 26px 15px;
    font-size: 15px;
    letter-spacing: .1px;
    outline: none;
    border: none;
    background: transparent;
    color: #333;
`;

export const SignupButton = styled.button`
    border-radius: 20px;
    padding: 2px 16px;
    border: 2px solid #ff5876;
    font-family: "CircularBold";
    color: rgba(255,255,255,.8);
    display: block;
    font-size: 15px;
    letter-spacing: .1px;
    outline: none;
    background: transparent;
    color: #333;
`;