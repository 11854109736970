import { getType } from 'typesafe-actions';
import * as actions from '../actions';
import { 
  initEntityState, 
  entityLoadingStarted, 
  entityLoadingSucceeded, 
  entityLoadingFailed
} from '../utils';

import { tourDetail5 } from '../../mocks/mockTourData';

export const defaultState = {
  newRelease: initEntityState(null),
  comingSoon: initEntityState(null),
  freeExperience: initEntityState(null),
  topExperience: initEntityState(null),
  // tourDetails: initEntityState(null)
  // override default data for temporary
  tourDetails: initEntityState(tourDetail5)
};

const states = (state = defaultState, action) => {
  switch (action.type) {
    
    case getType(actions.fetchNewRelease.request):
      return { ...state, newRelease: entityLoadingStarted(state.newRelease, action.payload) };
    case getType(actions.fetchNewRelease.success):
      return { ...state, newRelease: entityLoadingSucceeded(state.newRelease, action.payload) };
    case getType(actions.fetchNewRelease.failure):
      return { ...state, newRelease: entityLoadingFailed(state.newRelease) };
    
    case getType(actions.fetchComingSoon.request):
      return { ...state, comingSoon: entityLoadingStarted(state.comingSoon, action.payload) };
    case getType(actions.fetchComingSoon.success):
      return { ...state, comingSoon: entityLoadingSucceeded(state.comingSoon, action.payload) };
    case getType(actions.fetchComingSoon.failure):
      return { ...state, comingSoon: entityLoadingFailed(state.comingSoon) };
    
    case getType(actions.fetchFreeExperience.request):
      return { ...state, freeExperience: entityLoadingStarted(state.freeExperience, action.payload) };
    case getType(actions.fetchFreeExperience.success):
      return { ...state, freeExperience: entityLoadingSucceeded(state.freeExperience, action.payload) };
    case getType(actions.fetchFreeExperience.failure):
      return { ...state, freeExperience: entityLoadingFailed(state.freeExperience) };
    
    case getType(actions.fetchTopExperience.request):
      return { ...state, topExperience: entityLoadingStarted(state.topExperience, action.payload) };
    case getType(actions.fetchTopExperience.success):
      return { ...state, topExperience: entityLoadingSucceeded(state.topExperience, action.payload) };
    case getType(actions.fetchTopExperience.failure):
      return { ...state, topExperience: entityLoadingFailed(state.topExperience) };
    
    case getType(actions.fetchTourDetails.request):
      return { ...state, tourDetails: entityLoadingStarted(state.tourDetails, action.payload) };
    case getType(actions.fetchTourDetails.success):
      return { ...state, tourDetails: entityLoadingSucceeded(state.tourDetails, action.payload) };
    case getType(actions.fetchTourDetails.failure):
      return { ...state, tourDetails: entityLoadingFailed(state.tourDetails) };
    
    default:
      return state;
  }
};

export default states;