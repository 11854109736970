import { memo, useCallback, useState, useEffect } from "react";
import Login from "../../pages/homepage/authentication/login";
import Register from "../../pages/homepage/authentication/register";
import { useNavigate } from "react-router-dom";
import { usersState, accessToken, tourDetailsState } from "../../store/selectors";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { logout, checkToken } from "../../store/actions/thunks/users";
import { logoutActions } from "../../store/actions";
import { MainLogo, LogoutButton, NavButton, SignupButton, NavigationWrapper } from "./Styles";

const Navigation = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
	const [showLogin, setShowLogin] = useState(false);
	const [showRegister, setShowRegister] = useState(false);
    const users = useSelector(usersState);
    const token = useSelector(accessToken);
    // const tourDetailsState = useSelector(tourDetailsState);
    // const tourDetails = tourDetailsState.data;

    const postLogout = useCallback(() => {
        try {
            dispatch(logout(token))
            dispatch(logoutActions());
            navigate('/')
        } catch(err) {
            console.log(err)
        }
    }, [token]);

    const openLoginForm = () => {
        console.log('opening login form')
        setShowLogin(!showLogin);
    }

    useEffect(() => {
        console.log('re-render');
    }, []);

    return (
        <NavigationWrapper className="init show">
            <div className="navbar-default-white navbar-fixed-top sticky">
                <div className="container">
                    
                    <div className="row p-3-vh">
                        
                        <MainLogo onClick={() => navigate('/')} className="logo centered">
                            <img className="h-100 init" alt="logo" src="/img/logo-1.png"/>
                            <img className="h-100 show" alt="logo" src="/img/logo.png"/>
                        </MainLogo> 
                        
                        <div className="white menu-init" id="main-menu">
                        <nav id="menu-center">
                        <ul>
                            <li> 
                                <NavButton onClick={() => navigate('/self_guide_tour')}>Self Guide Tour</NavButton>
                            </li>
                            <li>
                                <a href="https://lokalocal.com/virtual-reality" target="_blank">VR Production
                            </a>
                            </li>
                            <li>
                                <a href="https://lokalocal.com/blog" target="_blank">Blog
                            </a>
                            </li>
                            <li>
                                <a href="https://lokalocal.com/press" target="_blank">Press
                            </a>
                            </li>
                            <li>
                                <a href="https://lokalocal.com/career" target="_blank">Career
                            </a>
                            </li>
                            {!users.data &&
                            <>
                                <li>
                                    <NavButton onClick={() => openLoginForm()}>Log in</NavButton>
                                </li>
                                <li>
                                    <SignupButton className="border-rad" onClick={() => setShowRegister(!showRegister)}>Sign Up</SignupButton>
                                </li>
                            </>
                            }
                            {users.data &&
                                <>
                                    {/* <li>
                                        <a href="#" onClick={() => navigate('/booking')}>Booking</a>
                                    </li> */}
                                    <li>
                                        <NavButton onClick={() => navigate('/purchased_tour')}>My Purchase</NavButton>
                                    </li>
                                    <li className="dropdown">
                                        <a className="icon-login" href="#">
                                        <i className="big fa fa-user-circle"></i>
                                        <span className="loginicon"></span>
                                        </a>
                                        <ul className="maindropdown">
                                        <li><LogoutButton onClick={() => postLogout()}>Log out</LogoutButton></li>
                                        </ul>
                                    </li>
                                </>
                            }
                        </ul>
                        </nav>
                        </div>

                        <div id="showmobile">
                            <i className="fa fa-bars"></i>
                        </div>

                    </div>
                    
                </div>
            </div>
            
			<Login show={showLogin} toggleLogin={() => setShowLogin(!showLogin)}/>
			<Register show={showRegister} toggleRegister={() => setShowRegister(!showRegister)} />
        </NavigationWrapper>
    );
}

export default memo(Navigation);