// import { createSelector, createStructuredSelector } from "reselect";


//Store Selectors
export const usersState = (state) => state.user.users;
export const accessToken = (state) => state.user.accessToken;

export const newReleaseState = (state) => state.selfguide.newRelease;
export const comingSoonState = (state) => state.selfguide.comingSoon;
export const freeExperienceState = (state) => state.selfguide.freeExperience;
export const topExperienceState = (state) => state.selfguide.topExperience;

export const tourDetailsState = (state) => state.selfguide.tourDetails;