import { combineReducers } from 'redux';
import usersReducer from './users';
import selfguideReducer from './selfguide';

export const rootReducer = combineReducers({
  user: usersReducer,
  selfguide: selfguideReducer
});

const reducers = (state, action) => rootReducer(state, action);

export default reducers;