import styled, { createGlobalStyle } from 'styled-components';

export const HomePageBackground = createGlobalStyle`
    body {
        background: #272727;
    }
`;

export const HomePageWrapper = styled.div`
    .query-input h5 {
        color: #272727;
    }
    .mainnav .form-search-top .searchmenu ul {
        top: -5px !important;
    }
    .brand {
        width: auto;
    }
    .menunew {
        display: flex;
        width: auto;
        justify-content: space-between;
    }
    .mainmenunew {
        padding: 0;
        display: flex;
        width: 100%;
        justify-content: flex-end;
    }

    .menu-wrapper {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    //temporary fixes for homepage
    .btn-link {
        text-decoration: none;
        color: #212529;
    }
    .owl-stage {
        display: flex;
        gap: 15px;

        .owl-item {
            width: 16%;
        }
    }

    .vr-title {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    #catgoricon {
        .owl-item {
            width: 160px;
            white-space: nowrap;
        }
    }

    #what-news {
        .owl-item {
            width: 28.7%;
        }
    }

    .slick-list {
        height: auto !important;
    }

    .slick-slide {
        padding: 0 7.5px;
        box-sizing: border-box;
        // height: auto;
    }

    .slick-track {
        display: flex;
        flex-direction: row;
        // flex-wrap: nowrap;
        // align-items: stretch;
        align-items: stretch;
        // flex-direction: column;
        flex-grow: 1;
    }

    .desc {
        // height: 58px;
    }

    .modal.show {
        display: block;
        background: #00000080;
    }
`;

export const SliderArrow = styled.div`
    width: 32px;
    height: 32px;
    line-height: 32px;
    border-radius: 32px;
    padding: 0;
    text-align: center;
    background: #fff;
    border: 1px solid rgba(0,0,0,.1);

    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;

    &.prev {
        left: -15px;
        z-index: 1;
    }

    &.next {
        right: -15px;
        z-index: 1;
    }
`;