import styled from 'styled-components';

export const DetailWrapper = styled.div`
    .slick-list {
        height: auto !important;
    }

    .slick-slide {
        padding: 0 7.5px;
        box-sizing: border-box;
    }
`;

export const LoadingState = styled.div`
    height: 100vh;
    width: 100vw;
    background: white;

    .preloader {
        display: flex;
        align-items: center;
        height: 100%;
        justify-content: center;
        font-size: 21px;
        font-weight: 600;
        color: var(--primary-color);
    }
`;
