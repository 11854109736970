import styled from "styled-components";

export const CardWrapper = styled.a`
    cursor: pointer;
    // max-height: 560px;
`;

export const LoadingWrapper = styled.a`
    cursor: pointer;
    width: ${props => (props.comingSoon || props.topExperience) ? '270px' : '300px'} !important;
    height: ${props => props.comingSoon ? '450px' : '560px'};
    background: #efefef;
`;