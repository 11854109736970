import { memo, useRef, useState, useMemo, useEffect } from "react";
import { HomePageWrapper, HomePageBackground, SliderArrow } from './Styles';
import { Canvas, useFrame, useThree,  extend, useLoader } from "react-three-fiber";
import * as THREE from "three";
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';
import Slider from "react-slick";
import Navigation from '../../components/navigation';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { fetchNewRelease, fetchComingSoon, fetchFreeExperience } from '../../store/actions/thunks/selfguide';
import  * as selectors from '../../store/selectors'
import { useSelector } from 'react-redux';
import Cards from '../../components/cards';

extend({OrbitControls})

const HomePage = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
    const mapRef = useRef();
    const texture = useMemo(() => new THREE.TextureLoader().load('/assets/img/mainpagevr.jpg'), []);
    const deg2rad = degrees => degrees * (Math.PI / 180);
    const Orbit = () => {
        const {camera, gl} = useThree()
        camera.rotation.set(deg2rad(0), 0, 0);
        camera.position.set(0, 0, 0)
        return(
            <orbitControls args={[camera, gl.domElement]}/>
        );
    }

	const newReleaseState = useSelector(selectors.newReleaseState);
	const comingSoonState = useSelector(selectors.comingSoonState);
	const freeExperienceState = useSelector(selectors.freeExperienceState);
	
	useEffect(() => {
		dispatch(fetchNewRelease());
		dispatch(fetchComingSoon());
		dispatch(fetchFreeExperience());
		console.log('DISPATCH REQUEST');
	}, [dispatch]);

    const Background = props => {
        const { gl } = useThree();
        const vec = new THREE.Vector3();

        let lon = 0;
        let lat = 0;
        let phi = 0;
        let theta = 0;

        useFrame( state => {
            // // console.log(frame.clock);
            // // state.camera.lookAt
            // lon += 0.1;
            // lat = Math.max( - 85, Math.min( 85, lat ) );
            // phi = deg2rad( 90 - lat );
            // theta = deg2rad( lon );
            // let camera = state.camera;
            // console.log(theta);
            // let x = 500 * Math.sin( phi ) * Math.cos( theta );
            // // // console.log(x)
            // let y = 500 * Math.cos( phi );
            // let z = 500 * Math.sin( phi ) * Math.sin( theta );
            // // camera.position.lerp(vec.set(x, y, z), .01);
            // camera.position.set(vec.set(x, y, z));
            // camera.updateProjectionMatrix();
            // // camera.lookAt( camera.target );
        });
        
        const formatted = new THREE.WebGLCubeRenderTarget(texture.image.height).fromEquirectangularTexture(gl, texture)
        return(
            <primitive attach="background" object={formatted.texture} />
        )
    }
	
	const PrevButton = (props) => {
		const { style, onClick } = props;

		return (
			<SliderArrow 
				className='slick-arrow prev'
				style={{ ...style, display: "block"}}
				onClick={onClick}
			>
				<i className="fa fa-chevron-left" aria-hidden="true"></i>
			</SliderArrow>
		);
	}
	
	const NextButton = (props) => {
		const { style, onClick } = props;
		return (
			<SliderArrow 
				className='slick-arrow next'
				style={{ ...style, display: "block"}}
				onClick={onClick}
			>
				<i className="fa fa-chevron-right" aria-hidden="true"/>
			</SliderArrow>
		);
	}

	const newRelease = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		initialSlide: 0,
		adaptiveHeight: true,
		nextArrow: <NextButton />,
      	prevArrow: <PrevButton />,
		responsive: [
		  {
			breakpoint: 1024,
			settings: {
			  slidesToShow: 3,
			  slidesToScroll: 3,
			  infinite: true,
			  dots: false
			}
		  },
		  {
			breakpoint: 600,
			settings: {
			  slidesToShow: 2,
			  slidesToScroll: 2,
			  initialSlide: 2
			}
		  },
		  {
			breakpoint: 480,
			settings: {
			  slidesToShow: 1,
			  slidesToScroll: 1
			}
		  }
		]
	};

	const comingSoon = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 3,
		slidesToScroll: 1,
		initialSlide: 0,
		adaptiveHeight: true,
		nextArrow: <NextButton />,
      	prevArrow: <PrevButton />,
		responsive: [
		  {
			breakpoint: 1024,
			settings: {
			  slidesToShow: 3,
			  slidesToScroll: 3,
			  infinite: true,
			  dots: false
			}
		  },
		  {
			breakpoint: 600,
			settings: {
			  slidesToShow: 2,
			  slidesToScroll: 2,
			  initialSlide: 2
			}
		  },
		  {
			breakpoint: 480,
			settings: {
			  slidesToShow: 1,
			  slidesToScroll: 1
			}
		  }
		]
	};
	
	const freeExperience = {
		dots: false,
		infinite: true,
		speed: 500,
		slidesToShow: 4,
		slidesToScroll: 1,
		initialSlide: 0,
		adaptiveHeight: true,
		nextArrow: <NextButton />,
      	prevArrow: <PrevButton />,
		responsive: [
		  {
			breakpoint: 1024,
			settings: {
			  slidesToShow: 3,
			  slidesToScroll: 3,
			  infinite: true,
			  dots: false
			}
		  },
		  {
			breakpoint: 600,
			settings: {
			  slidesToShow: 2,
			  slidesToScroll: 2,
			  initialSlide: 2
			}
		  },
		  {
			breakpoint: 480,
			settings: {
			  slidesToShow: 1,
			  slidesToScroll: 1
			}
		  }
		]
	};

	const placeholderArr = [1,2,3,4];

    return (
        <HomePageWrapper>
				<Navigation/>
				<section className="jumbotron" style={{backgroundImage: 'img/img.jpg'}}>
				<video className="mainbgvideo" id="mainvideo" autoPlay="autoplay" loop  muted>
					<source src="https://storage.googleapis.com/coverr-main/mp4/Mt_Baker.mp4" type="video/mp4"/>
					</video>
				<div className="container">
					<div className="row">
					<div className="col-md-9 m-auto">
						<div className="jumbomain">
						<h1>EXPERIENCE <br/>VIRTUAL JOURNEYS</h1>
						<p className="mt-4">Discover the sights and stories of Malaysia with self-guided 360° virtual tours.
						</p>

						</div>
						<div className="icon-scroll">
							<div className="icon-scroll-screen"></div>
						</div>
					</div>
					</div>  
				</div>
				</section>

				<section className="gray">
				<div className="container">
					<div className="row">
					<div className="col-12 px-3">
						<h3>New Releases</h3>
					</div>

					<div className="col-12">

						<div className="vr-catgor">
						<Slider {...newRelease} id="newrelease" className="row mx-0 owl-carousel owl-theme">
							{newReleaseState.data ? newReleaseState.data.map((item, index) => (
								<Cards
									key={index}
									{...item}
								/>
							)) : (
								placeholderArr.map((i) => (
									<Cards key={i} loading/>
								))
							)}
						</Slider>
						</div>
						
					</div>

					</div>
				</div>
				</section>

				<section>
				<div className="container">
					<div className="row">
					<div className="col-md-3 m-left m-md-0 mb-5">
						<h3 className="mb-3">Coming Soon</h3>
						<p className="half-color">Embark on an immersive exploration of Malaysia’s best destinations, anywhere in the world. </p>
						<a className="btn pink-button" href="#">View All</a>
					</div>
					<div className="col-md-8 m-auto">

						<div className="vr-catgor p-0">
						<Slider {...comingSoon} id="comingsoon" className="row mx-0 owl-carousel owl-theme">

							{comingSoonState.data ? comingSoonState.data.map((item, index) => (
								<Cards
									key={index}
									{...item}
									comingSoon
								/>
							)) : (
								placeholderArr.map((i) => (
									<Cards key={i} loading comingSoon/>
								))
							)}
						
						</Slider>
						</div>
					</div>
					</div>
				</div>
				</section>

				<section>
				<div className="container">
					<div className="row">
					<div className="col-12 px-3">
						<h3>Free to Experience</h3>
					</div>

					<div className="col-12">

						<div className="vr-catgor">
						<Slider {...freeExperience} id="expfree" className="row mx-0 owl-carousel owl-theme">

							{freeExperienceState.data ? freeExperienceState.data.map((item, index) => (
								<Cards
									key={index}
									{...item}
								/>
							)) : (
								placeholderArr.map((i) => (
									<Cards key={i} loading/>
								))
							)}
						
						</Slider>
						</div>
						
					</div>

					</div>
				</div>
				</section>
    	</HomePageWrapper>
    );
}

export default memo(HomePage);