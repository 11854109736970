import { memo, useState } from 'react';
import { PinkButton, StyledSection } from './styles';
import { useNavigate } from "react-router-dom";

const Activation = () => {
    const navigate = useNavigate();
    const [activationCode, setActivationCode] = useState('1234567');

    const handleChange = (val) => {
        setActivationCode(val.target.value);
    }

    const goToSelfGuide = () => {
        navigate("/self_guide_tour");
    }

    return (
        <>

            <StyledSection id="activationpage" className="noselect py-5">
                <div className="container">
                    <div className="row">
                    <div className="col-10">
                        <div className="logo">
                        <img src="img/logo.png" alt="logo"/>
                        </div>
                    </div>
                    <div className="col-10">
                        <h1 className="mt-5">Kelantan Self-Guided Virtual Journey: Cradle of Malay Culture</h1>
                        <p className="mt-3">Welcome <strong>Rachael</strong>!  You have arrived at Kelantan - a treasure trove of Malay cultural heritage. This conservative and captivating state is the birthplace of traditional culture in Malaysia, from shadow puppetry to giant kites, intricate textiles and handicrafts.
                Along with picturesque beaches, charming villages, Thai temples and distinctive cuisine, you will soon discover some of the hidden stories and secrets of Kelantan in this interactive 360° experience.</p>
                    </div>
                    <div className="col-10 mt-3">
                        <h5>Key in your activation code to begin.</h5>
                    </div>
                    <div className="col-10 m-left">
                        <div className="boxnumber">
                        
                        <input className="inputactication" type="text" id="fname" name="fname" value=""  placeholder="1234567890" autofocus/>
                        </div>
                    </div>
                    <div className="col-10 mt-2">
                    <PinkButton className="pink-button">Let's Go!</PinkButton>
                    </div>
                    <div className="col-10 mt-3">
                        <div className="errorcode boxrange"><span>* Oops! Check that you have entered the right code</span></div>
                        <div className="expiredcode boxrange"><span>* This activation code has expired</span></div>
                    </div>
                    </div>
                </div>
            </StyledSection>
        </>
    );
}

export default memo(Activation);