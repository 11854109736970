import { getType } from 'typesafe-actions';
import * as actions from '../actions';
import { 
  initEntityState, 
  entityLoadingStarted, 
  entityLoadingSucceeded, 
  entityLoadingFailed, 
  remapObject 
} from '../utils';

export const defaultState = {
  users: initEntityState(null),
  accessToken: ''
};

const states = (state = defaultState, action) => {
  switch (action.type) {
    
    case getType(actions.getUser.request):
      return { ...state, users: entityLoadingStarted(state.users, action.payload) };
    case getType(actions.getUser.success):
      return { ...state, users: entityLoadingSucceeded(state.users, action.payload) };
    case getType(actions.getUser.failure):
      return { ...state, users: entityLoadingFailed(state.users) };
    
    case getType(actions.login.success):
      return { ...state, accessToken: action.payload.access_token, users: entityLoadingSucceeded(state.users, action.payload.user)};
    
    case getType(actions.logoutActions):
      return { ...state, accessToken: '', users: initEntityState(null)};
    
    default:
      return state;
  }
};

export default states;
