import React from 'react';
import ReactDOM from 'react-dom/client';
// import $ from 'jquery';
// import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
// import Popper from 'popper.js';
import 'bootstrap/dist/js/bootstrap.bundle.min';
// import '../src/assets/css/main.css';

// <!-- plugin css -->
// <link href="css/bootstrap.min.css" rel="stylesheet">
// <!-- animsition -->
// <link href="css/animsition.min.css" rel="stylesheet">
// <!-- lightgallery -->
// <link href="css/css/lightgallery.css" rel="stylesheet">
// <!-- revolution slider css -->
// <link rel="stylesheet" type="text/css" href="rs-plugin/css/settings.css" media="screen" />
// <link rel="stylesheet" href="css/rev-settings.css" type="text/css">
// <!-- owl -->
// <link href="css/owl.carousel.css" rel="stylesheet">
// <link href="css/owl.theme.css" rel="stylesheet">
// <!-- main css -->
import '../src/assets/css/animated-on3step.css';
import '../src/assets/css/style.css';
// <link href="css/animated-on3step.css" rel="stylesheet">
// <link href="css/style.css" rel="stylesheet">
// <link href="css/queriesstyle.css" media="all" rel="stylesheet"></link>

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import App from './pages';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from './components/ScrollTop';

import { Provider } from 'react-redux'
import store from './store';

//enable this for mocking response
if (process.env.NODE_ENV === 'development') {
  const { worker } = require('./mocks/browser')
  worker.start({
    onUnhandledRequest: 'bypass'
  })
}

const root = ReactDOM.createRoot(
  document.getElementById('root')
);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <ScrollToTop />
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
