import { 
    createAction as action, 
    createAsyncAction as asyncAction 
} from 'typesafe-actions';

export const login = asyncAction(
    'auth/LOGIN_USER',
    'auth/LOGIN_USER_SUCCESS',
    'auth/LOGIN_USER_FAIL'
)();

export const getUser = asyncAction(
    'auth/GET_USER',
    'auth/GET_USER_SUCCESS',
    'auth/GET_USER_FAIL'
)();

export const fetchNewRelease = asyncAction(
    'auth/FETCH_NEW_RELEASE',
    'auth/FETCH_NEW_RELEASE_SUCCESS',
    'auth/FETCH_NEW_RELEASE_FAIL'
)();

export const fetchComingSoon = asyncAction(
    'auth/FETCH_COMING_SOON',
    'auth/FETCH_COMING_SOON_SUCCESS',
    'auth/FETCH_COMING_SOON_FAIL'
)();

export const fetchFreeExperience = asyncAction(
    'auth/FETCH_FREE_EXPERIENCE',
    'auth/FETCH_FREE_EXPERIENCE_SUCCESS',
    'auth/FETCH_FREE_EXPERIENCE_FAIL'
)();

export const fetchTopExperience = asyncAction(
    'auth/FETCH_TOP_EXPERIENCE',
    'auth/FETCH_TOP_EXPERIENCE_SUCCESS',
    'auth/FETCH_TOP_EXPERIENCE_FAIL'
)();

export const fetchTourDetails = asyncAction(
    'auth/FETCH_TOUR_DETAILS',
    'auth/FETCH_TOUR_DETAILS_SUCCESS',
    'auth/FETCH_TOUR_DETAILS_FAIL'
)();

export const logoutActions = action('auth/LOGOUT_USER')();