import React, { memo, useState } from 'react';
import TopNavigation from './topNavigation';
import { SelfGuideWrapper, LogoButton } from './Styles'; 
import { useNavigate } from 'react-router-dom';

const SelfGuide = () => {

    const navigate = useNavigate();
    const [showOverview, setShowOverview] = useState(false);

    return (
        <SelfGuideWrapper>
            <div id="topnav" className="relative">
                <header>
                    <div className="topmenu">
                        <div className="brand">
                            <LogoButton onClick={() => navigate('/')}><img className="h-100 init" alt="logo" src="/img/logo.png"/></LogoButton>
                        </div>
                        <div className="menu">
                            <ul className="mainmenunew right">
                                <li>
                                    <button id="fullblockbtn" className="pink-button m-0"  onClick={() => setShowOverview(!showOverview)}>Overview</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="spacer-menu"></div>
                    <div className="botmenu">
                        <ul className="menubot">
                            <li>
                                <a href="#">All</a>
                            </li>
                            <li>
                                <a href="#">Attractions</a>
                            </li>
                            <li>
                                <a href="#">Entertaiment</a>
                            </li>
                            <li>
                                <a href="#">Food and Drink</a>
                            </li>
                            <li>
                                <a href="#">Tour</a>
                            </li>
                            <li>
                                <a href="#">Hotel</a>
                            </li>
                            <li>
                                <a href="#">Sightseeing</a>
                            </li>
                        </ul>
                    </div>
                </header>
            </div>

            <div className="container-fluid" id="mapcontent">
                <div className="row">
                    <div className="col-lg-6 px-0">
                        <div className="contentmap">
                            <div className="topmainmap">
                                <div className="heading">
                                    Kelantan: Self-Guided Audio Tour
                                </div>
                                <div className="subheading">
                                    Discover 30+ attractions in Kelantan in 360°
                                </div>
                            </div>
                            <hr/>
                            <a className="botmainmap" href="#">
                                <div className="imgrounded">
                                    <img className="imgcov" src="http://www.tinywanderer.com/wp-content/uploads/2018/05/IMG_20180306_110617.jpg"/>
                                    <div className="imghover">
                                        <img src="http://www.tinywanderer.com/wp-content/uploads/2018/05/IMG_20180306_110617.jpg"/>
                                        <video autoPlay muted loop className="bgvideo">
                                        <source src="/assets/rain.mp4" type="video/mp4"/>
                                        </video>
                                    </div>
                                </div>
                                <div className="detailmap">
                                    <h5 className="mb-3">Cahaya Bulan Beach</h5>
                                    <div className="content">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco</p>
                                        <p className="stories mb-3">10 minutes - <span>2</span> stories</p>
                                        <small className="colorpink"><strong>Explore</strong></small>
                                        <div className="marker">
                                            <img src="/img/marker.png" alt="marker"/>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <hr/>
                            <a className="botmainmap" href="#">
                                <div className="imgrounded">
                                    <img className="imgcov" src="http://www.tinywanderer.com/wp-content/uploads/2018/05/IMG_20180306_110617.jpg"/>
                                    <div className="imghover">
                                        <img src="http://www.tinywanderer.com/wp-content/uploads/2018/05/IMG_20180306_110617.jpg"/>
                                        <video autoPlay muted loop className="bgvideo">
                                        <source src="" type="video/mp4"/>
                                        </video>
                                    </div>
                                </div>
                                <div className="detailmap">
                                    <h5 className="mb-3">Cahaya Bulan Beach</h5>
                                    <div className="content">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco</p>
                                        <p className="stories">10 minutes - <span>2</span> stories</p>
                                        <small className="colorpink"><strong>Explore</strong></small>
                                        <div className="marker">
                                            <img src="/img/marker.png" alt="marker"/>
                                        </div>
                                    </div>
                                </div>
                            </a>
                            <hr/>
                            <a className="botmainmap" href="#">
                                <div className="imgrounded">
                                    <img className="imgcov" src="http://www.tinywanderer.com/wp-content/uploads/2018/05/IMG_20180306_110617.jpg"/>
                                    <div className="imghover">
                                        <img src="http://www.tinywanderer.com/wp-content/uploads/2018/05/IMG_20180306_110617.jpg"/>
                                        <video autoPlay muted loop className="bgvideo">
                                        <source src="/assets/rain.mp4" type="video/mp4"/>
                                        </video>
                                    </div>
                                </div>
                                <div className="detailmap">
                                    <h5 className="mb-3">Cahaya Bulan Beach</h5>
                                    <div className="content">
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco</p>
                                        <p className="stories">10 minutes - <span>2</span> stories</p>
                                        <small className="colorpink"><strong>Explore</strong></small>
                                        <div className="marker">
                                            <img src="/img/marker.png" alt="marker"/>
                                        </div>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-6 px-0 mobilehide">
                        <div id="mainmap">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d127482.73095888077!2d101.53813724672852!3d3.1381525725129378!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4ececc1c7c97%3A0x524c8e31e929bc76!2sPetaling%20Jaya%2C%20Selangor%2C%20Malaysia!5e0!3m2!1sid!2sid!4v1629087106491!5m2!1sid!2sid" style={{ border:0 }} allowFullScreen="" loading="lazy"></iframe>
                        </div>
                    </div>
                </div>
            </div>

            <div id="fullblock" className={showOverview ? "init show" : "init"}>
                <div className="closefullblock" onClick={() => setShowOverview(!showOverview)}>
                    <i className="fa fa-times"></i>
                </div>
                <div className="imgblock">
                    <video autoPlay muted loop id="bgvideo">
                    <source src="/assets/rain.mp4" type="video/mp4"/>
                    </video>
                </div>
                <div className="contentblock">
                    <div className="bread"><span>Malaysia</span> {`>`} <span>Kelantan</span></div>
                    <h3>MALAYSIA</h3>
                    <p className="mb-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in volu</p>

            <h4 >Kelantan</h4>
                    <p className="mb-5">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
                    <hr/>
                    <div className="ctn3block">
                        <div className="ctn3">
                            <span className="num">30</span>
                            <span className="text">Attractions</span>
                        </div>
                        <div className="ctn3">
                            <span className="num">100</span>
                            <span className="text">Stories</span>
                        </div>
                        <div className="ctn3">
                            <span className="num">7.5</span>
                            <span className="text">Hours</span>
                        </div>
                    </div>
                    <hr/>
                    
                    <div className="row my-5">
                        <div className="col-md-6">
                            <div className="gray p-4 my-3">
                                <h5>Getting There</h5>
                                <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="gray p-4 my-3">
                                <h5>Getting Around</h5>
                                <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="gray p-4 my-3">
                                <h5>Eating</h5>
                                <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="gray p-4 my-3">
                                <h5>Shopping</h5>
                                <p className="mb-0">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum
                                </p>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="gray p-4 my-3">
                                <h5>Festivals</h5>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
                            </div>
                        </div>

                    </div>
                    

                    <hr/>
                    <span className="powered">Powered By Lokolocal <img src="/img/lokaicon.png" alt="imgtopbar"/></span>
                </div>
            </div>
        </SelfGuideWrapper>
    )
}

export default memo(SelfGuide);