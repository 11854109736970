import { memo, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { registerUser } from "../../../store/actions/thunks/users";

const Register = ({show, toggleRegister = () => {}}) => {

    const dispatch = useDispatch();
    const { register, handleSubmit } = useForm();
    const postRegister = useCallback((data) => {
        try {
           const response = dispatch(registerUser(data));
           console.log(response);
        } catch(err) {
            console.log(err)
        }
    }, []);

    return (
        <div className={`modal fade${show ? ' show' : ''}`} id="signUpModal" tabIndex="-1" role="dialog" aria-labelledby="signUpModalLabel" aria-hidden="true">
            <div className="modal-dialog" role="document">
            <div className="modal-content">
                <div className="modal-header">
                <button onClick={() => toggleRegister()} type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true"><i className="fa fa-times"></i></span>
                </button>
                </div>
                <div className="modal-body ">
                <h5 className="mb-2">Sign Up</h5>
                <form onSubmit={handleSubmit((data) => postRegister(data))}>
                    <div className="form-group mb-3">
                    <label htmlFor="email">Email address</label>
                    <input {...register("email")} type="email" className="form-control" id="email" placeholder="johndoe@gmail.com"/>
                    </div>
                    <div className="form-group mb-3">
                    <label htmlFor="first_name">First Name</label>
                    <input {...register("first_name")} type="text" className="form-control" id="first_name" placeholder="John"/>
                    </div>
                    <div className="form-group mb-3">
                    <label htmlFor="last_name">Last Name</label>
                    <input {...register("last_name")} type="text" className="form-control" id="last_name" placeholder="Doe"/>
                    </div>
                    <div className="form-group mb-3">
                    <label htmlFor="password">Password</label>
                    <input {...register("password")} type="password" className="form-control" id="password" placeholder="Your Password"/>
                    </div>
                    <button type="submit" className="d-block pink-button text-center mb-3">Sign up</button>
                    <p className="font-weight-normal text-center">Already have an account? <a href="" className="sign-up " data-toggle="modal" data-target="#signUpModal">Log in</a></p>
                </form>
                </div>
            </div>
            </div>
        </div>
    );
}

export default memo(Register);