import React from 'react';
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import Activation from './activation';
import SelfGuideTour from './virtualTour/selfGuide';
import Purchase from './profile/purchase';
import Footer from '../components/footer';
import HomePage from './homepage';
import NotFound from './NotFound';
import Detail from './detail';
import Booking from './booking';
import { useSelector } from 'react-redux';
import * as selectors from '../store/selectors';

const ProtectedRoute = ({ children }) => {
  const userState = useSelector(selectors.usersState);
  const user = userState.data;
  let location = useLocation();
  // const isAuth = auth.getToken() !== null;

  return (
    user ? children : <Navigate to="/" state={{ from: location }} replace />
  )
};

const App = () => (
  <div className="wraper">
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/details/:slug" element={<Detail />} />
      <Route path="/activation" element={<Activation />} />
      <Route path="/self_guide_tour" element={<SelfGuideTour />} />
      <Route path="/purchased_tour" element={
        <ProtectedRoute>
          <Purchase />
        </ProtectedRoute>
      } />
      {/* <Route path="/booking" element={
        <ProtectedRoute>
          <Booking />
        </ProtectedRoute>
      } /> */}
      <Route path="/booking" element={
          <Booking />
      } />
      <Route path="/404" element={<NotFound />} />
      <Route path="*" element={<Navigate to="/404" replace />} />
    </Routes>
  <Footer />
  </div>
);

export default App;