import axios from 'axios';
import * as actions from '../../actions';
import { BASE_URL } from '../../../core/constants';

const api = {
  baseUrl: 'http://localhost:3000',
  newRelease: '/selfguide/new_release',
  comingSoon: '/selfguide/coming_soon',
  freeExperience: '/selfguide/free_experience',
  topExperience: '/selfguide/top_experience',
  tourDetails: (id) => `/selfguide/details/${id}`,
}

export const Axios = axios.create();
export const Canceler = axios.CancelToken.source();

export const fetchNewRelease = () => async (dispatch) => {
  dispatch(actions.fetchNewRelease.request(Canceler.cancel));

  try {
    const { data } = await Axios.get(`${BASE_URL}${api.newRelease}`, {
      cancelToken: Canceler.token,
      params: {}
    });
    // console.log(data)
    dispatch(actions.fetchNewRelease.success(data));
  } catch (err) {
    dispatch(actions.fetchNewRelease.failure(err));
  }
};

export const fetchComingSoon = () => async (dispatch) => {
  dispatch(actions.fetchComingSoon.request(Canceler.cancel));

  try {
    const { data } = await Axios.get(`${BASE_URL}${api.comingSoon}`, {
      cancelToken: Canceler.token,
      params: {}
    });
    // console.log(data)
    dispatch(actions.fetchComingSoon.success(data));
  } catch (err) {
    dispatch(actions.fetchComingSoon.failure(err));
  }
};

export const fetchFreeExperience = () => async (dispatch) => {
  dispatch(actions.fetchFreeExperience.request(Canceler.cancel));

  try {
    const { data } = await Axios.get(`${BASE_URL}${api.freeExperience}`, {
      cancelToken: Canceler.token,
      params: {}
    });
    // console.log(data)
    dispatch(actions.fetchFreeExperience.success(data));
  } catch (err) {
    dispatch(actions.fetchFreeExperience.failure(err));
  }
};

export const fetchTopExperience = () => async (dispatch) => {
  dispatch(actions.fetchTopExperience.request(Canceler.cancel));

  try {
    const { data } = await Axios.get(`${BASE_URL}${api.topExperience}`, {
      cancelToken: Canceler.token,
      params: {}
    });
    // console.log(data)
    dispatch(actions.fetchTopExperience.success(data));
  } catch (err) {
    dispatch(actions.fetchTopExperience.failure(err));
  }
};

export const fetchTourDetails = (id) => async (dispatch) => {
  dispatch(actions.fetchTourDetails.request(Canceler.cancel));

  try {
    const { data } = await Axios.get(`${BASE_URL}${api.tourDetails(id)}`, {
      cancelToken: Canceler.token,
      params: {}
    });
    // console.log(data)
    dispatch(actions.fetchTourDetails.success(data));
  } catch (err) {
    dispatch(actions.fetchTourDetails.failure(err));
  }
};
