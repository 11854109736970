import { memo } from "react";
import { useNavigate } from "react-router-dom";
import { CardWrapper, LoadingWrapper } from "./Styles";

const Cards = ({
    cover,
    tags,
    title,
    views,
    likes,
    booked,
    link,
    loading,
    comingSoon,
    topExperience
}) => {
    
    const navigate = useNavigate();

    if (loading) return (
        <LoadingWrapper comingSoon={comingSoon} topExperience={topExperience} className="col-sm-6 col-6 item active" />
    );

    return (
        <CardWrapper onClick={() => navigate(`/details/${link}`)} className="col-sm-6 col-6 item active">
            <div className="img-item">
                <div className="imgrounded">
                <img className={`imgcov ${comingSoon ? 'third' : ''}`} src={cover}/>
                    <div className="imghover">
                        <img src={cover}/>
                    </div>
                </div>
                <div className="p-3">
                <div className="subtext">{tags}</div>
                <div className="desc autoheight">
                    <span>{title}</span>
                </div>
                <div className="icon">
                    <div className="main-icon">
                    <i className="fa fa-eye"></i>
                    <span>{views}K</span>
                    </div>
                    <div className="main-icon">
                    <i className="fa fa-heart"></i>
                    <span>{likes}</span>
                    </div>
                    <div className="main-icon">
                    <span><span>{booked}K+</span> Booked</span>
                    </div>
                </div>
            </div>
            </div>
        </CardWrapper>
    );
}

export default memo(Cards);