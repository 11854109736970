export const tour1 = {
    id: 1,
    cover: 'https://www.lokalocal.com/vr/lavender-garden/thumbnail.png',
    panocover: 'https://www.lokalocal.com/vr/lavender-garden/panocover.jpg',
    tags: ['Nature','Langkawi'],
    title: "Asia's Island Paradise The Colours of Georgetown",
    desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor 
    in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, 
    sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    views: 9,
    likes: 999,
    booked: 9,
    price: 35,
    link: 'asias-island-paradise-the-colours-of-georgetown'
};

export const tour2 = {
    id: 2,
    cover: 'https://www.lokalocal.com/vr/batu-pelanduk-beach/thumbnail.png',
    panocover: 'https://www.lokalocal.com/vr/batu-pelanduk-beach/panocover.jpg',
    tags: ['Nature','Kota Kinabalu'],
    title: "All Roads Lead to Borneo Mysteries of Labuan, Pearl of Borneo",
    desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor 
    in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, 
    sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    views: 9,
    likes: 999,
    booked: 9,
    price: 35,
    link: 'all-roads-lead-to-borneo-mysteries-of-labuan-pearl-of-borneo'
};

export const tour3 = {
    id: 3,
    cover: 'https://www.lokalocal.com/vr/istana-iskandariah/thumbnail.png',
    panocover: 'https://www.lokalocal.com/vr/istana-iskandariah/panocover.jpg',
    tags: ['Nature','Kuching'],
    title: "Borneo's River Kingdom Undiscovered Secrets of Perlis",
    desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor 
    in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, 
    sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    views: 9,
    likes: 999,
    booked: 9,
    price: 35,
    link: 'borneos-river-kingdom-undiscovered-secrets-of-perlis'
};

export const tour4 = {
    id: 4,
    cover: 'https://www.lokalocal.com/vr/cermin-lake/thumbnail.png',
    panocover: 'https://www.lokalocal.com/vr/cermin-lake/panocover.jpg',
    tags: ['Nature','Kuching'],
    title: "Cermin Lake",
    desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor 
    in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, 
    sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    views: 9,
    likes: 999,
    booked: 9,
    price: 35,
    link: 'cermin-lake'
};

export const tour5 = {
    id: 5,
    cover: 'https://www.lokalocal.com/vr/ipoh-street-art/thumbnail.png',
    panocover: 'https://www.lokalocal.com/vr/ipoh-street-art/panocover.jpg',
    tags: ['History','Perak'],
    title: "Ipoh Street Art",
    desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
    Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor 
    in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, 
    sunt in culpa qui officia deserunt mollit anim id est laborum.`,
    views: 9,
    likes: 999,
    booked: 9,
    price: 35,
    link: 'ipoh-street-art'
};

export const tourDetail1 = {
    ...tour1,
    media: [
        {
            url: '/assets/rain.mp4',
            type: 'video', //video or image
            title: 'Video Title',
            desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
        }
    ],
    tags: {
        place_count: 22,
        image_count: 102,
        audio_length: 42,
        street_view: true
    }
}

export const tourDetail2 = {
    ...tour2,
    media: [
        {
            url: '/assets/rain.mp4',
            type: 'video', //video or image
            title: 'Video Title',
            desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
        }
    ],
    tags: {
        place_count: 22,
        image_count: 102,
        audio_length: 42,
        street_view: true
    }
}

export const tourDetail3 = {
    ...tour3,
    media: [
        {
            url: '/assets/rain.mp4',
            type: 'video', //video or image
            title: 'Video Title',
            desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
        }
    ],
    tags: {
        place_count: 22,
        image_count: 102,
        audio_length: 42,
        street_view: true
    }
}

export const tourDetail4 = {
    ...tour4,
    media: [
        {
            url: '/assets/rain.mp4',
            type: 'video', //video or image
            title: 'Video Title',
            desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
        }
    ],
    tags: {
        place_count: 22,
        image_count: 102,
        audio_length: 42,
        street_view: true
    }
}

export const tourDetail5 = {
    ...tour5,
    media: [
        {
            url: '/assets/rain.mp4',
            type: 'video', //video or image
            title: 'Video Title',
            desc: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. 
            Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.`,
        }
    ],
    tags: {
        place_count: 22,
        image_count: 102,
        audio_length: 42,
        street_view: true
    }
}

export const newReleases = [
    tour1,
    tour2,
    tour3,
    tour4
];

export const comingSoon = [
    tour1,
    tour2,
    tour3,
    tour4,
    tour5,
];

export const freeExperience = [
    tour1,
    tour2,
    tour3,
    tour4,
    tour5,
];